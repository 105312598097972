.theme-custom-0a {
	.sidebar {
		background-color: #2C3E50;
	}
	.sidebar a:hover{
		color: #4A90E2;
	}	
	.content {
		max-width: 1200px;
	} 

	.site__title {
		font-family: 'Saira Extra Condensed', serif;
	}

	.site__title_highlight {
		font-weight: bold;
		color: #4A90E2;
	}

	.disclaimer {
		padding-top: 16px;
		padding-bottom: 24px;
  		font-size: .7rem;
  		line-height: 1.1rem;
  		text-align: center;
	}

	.post-list__item {
		padding-bottom: 1em;
		margin-bottom: 1em;
		border-bottom: 1px solid #2C3E50;
		font-size: 16px;
	}
	
	.item__title--big {
		font-family: 'Saira Extra Condensed', serif;
		font-weight: bold;
	}

	.post-list__item h2 {
		font-size: 1.3rem;
	} 

	h1 {
		font-family: 'Saira Extra Condensed', serif;
		font-weight: bold;		
		color: #4A90E2;
	}

	h2 {
		font-family: 'Saira Extra Condensed', serif;
		font-weight: bold;
	}

	.content a {
		color: #4A90E2;
	}
	.content a:hover, a:focus, a:active {
		color: #2C3E50;
	}
	
	.related-posts li a:hover {
		color: $purple;
	}

	.navigation-prev, .navigation-next  {
		border-color: #2C3E50;
	}
	ul.pagination li.page-item.active a {
		color: #2C3E50;
		border-bottom: 3px solid #2C3E50;
	}

	.alert {
		color: black; 
		margin-top: 0px;
	} 

	.alert-heading {
		font-weight: bold;
		line-height: 0px;
	} 

	.alert-warning {
		background-color: #F8E09A;
		border: 2px solid #FFD700;
	}
}